const Config = {
  PHASE: process.env.PHASE,
  LOGGER_MODE: process.env.LOGGER_MODE,
  PAGE_TITLE: process.env.PAGE_TITLE,
  WEB_HOST: process.env.WEB_HOST,
  API_HOST: process.env.API_HOST,
  COOKIE_DOMAIN: process.env.COOKIE_DOMAIN,
  API_MOCK_HOST: process.env.API_MOCK_HOST,
  API_CONTENTS_HOST: process.env.API_CONTENTS_HOST,
  STATIC_FILE_PATH: process.env.STATIC_FILE_PATH
}

export default Config

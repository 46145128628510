import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'

@Module({
  name: 'sampleStorage',
  stateFactory: true, // nuxt에서는 팩토리 함수 생성 필요
  namespaced: true
})
export default class SampelPreModule extends VuexModule {
  wheels = 2
  abouts: string[] = []

  @Mutation
  incrWheels (extra: number) {
    this.wheels += extra
  }

  @Mutation
  setAbouts (abouts: string[]) {
    this.abouts = abouts
  }

  @Action
  getAbouts () {
    const abouts = ['dd', 'ee']
    this.setAbouts(abouts)
  }

  get axles () {
    return this.wheels / 2
  }
}

import { Middleware } from '@nuxt/types'

import { HOME_PAGE } from '~/service/router/router-service'
import { ACCESS_TOKEN, REFRESH_TOKEN } from '~/service/auth/constants'

const unAuthenticated: Middleware = (context) => {
  const accessToken = context.$cookies.get(ACCESS_TOKEN)
  const refreshToken = context.$cookies.get(REFRESH_TOKEN)
  if (accessToken && refreshToken) {
    context.redirect(HOME_PAGE)
  }
}
export default unAuthenticated

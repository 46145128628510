import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError
} from 'axios'
import { Context, Plugin } from '@nuxt/types'
import Config from '~/config/configuration'

import { ACCESS_TOKEN, REFRESH_TOKEN } from '~/service/auth/constants'
export { AxiosInstance, AxiosResponse } from 'axios'

export const axiosInstance: AxiosInstance = axios.create({
  baseURL: Config.API_HOST,
  timeout: 30000,
  headers: {
    withCredentials: true
  }
})

class HttpError extends Error {
  constructor (message?: string) {
    super(message) // 'Error' breaks prototype chain here
    this.name = 'HttpError'
    Object.setPrototypeOf(this, new.target.prototype) // restore prototype chain
  }
}

// plugin 등록
declare module '@nuxt/types' {
  interface Context {
    $api: AxiosInstance
  }
}

const axioxPlugin: Plugin = (context: Context) => {
  const requestHandeler = (config: AxiosRequestConfig) => {
    config.headers = config.headers || {}
    config.headers.common = config.headers.common || {}

    config.headers.common._ata_token = context.$cookies.get(ACCESS_TOKEN) || ''
    config.headers.common._atr_token = context.$cookies.get(REFRESH_TOKEN) || ''
    return config
  }

  const responseHandler = (response: AxiosResponse<any>) => {
    if (response.status === 200) {
      const data = response?.data
      // if (!data) {
      //   throw new HttpError('Error Api')
      // }
      return response
    }
    throw new HttpError('Error Api Status')
  }

  const responseErrorHandler = (error: AxiosError) => {
    return Promise.reject(error)
  }

  axiosInstance.interceptors.request.use(requestHandeler)
  axiosInstance.interceptors.response.use(responseHandler, responseErrorHandler)

  context.$api = axiosInstance
}

export default axioxPlugin

import { onGlobalSetup, onErrorCaptured } from '@nuxtjs/composition-api'
import AuthService from '~/service/auth/auth-service'

export default () => {
  onGlobalSetup(() => {
    globalErrorHandler()
  })
}

const globalErrorHandler = () => {
  onErrorCaptured((error: Error) => {
    // @ts-ignore
    const statusCode = error?.response?.status
    if (typeof statusCode !== 'undefined' && statusCode === 403) {
      TokenValidation()
    }
  })
}

const TokenValidation = async () => {
  const authService: AuthService = new AuthService()
  try {
    await authService.checkedSession()
  } catch (error) {
    authService.clearToken()
    location.href = authService.navigateToSessionExpiration()
  }
}

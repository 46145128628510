var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, LitElement } from "lit";
import { customElement, state, queryAssignedElements } from "lit/decorators.js";
import { prefixName } from "../types/types";
const tagType = 'non-duplicate';
const elementName = `${prefixName}-${tagType}-tag-list`;
let TNonDuplicateTagList = class TNonDuplicateTagList extends LitElement {
    constructor() {
        super();
        this._isLoaded = false;
        this.checkDuplicated = (oldText, value) => {
            const tags = this.tagItems;
            const duplicateList = tags.filter(obj => obj.tagName === value);
            if (duplicateList.length > 0) {
                const oldItem = tags.filter(obj => obj.tagName === oldText);
                const duplicateItem = oldItem[0];
                duplicateItem.focusInputTag();
                return false;
            }
            return true;
        };
        this.addEvent = (e) => {
            e.preventDefault();
            const event = e;
            const duplicatedTag = event.detail;
            if (duplicatedTag.oldText === duplicatedTag.text) {
                duplicatedTag.success();
                return;
            }
            const result = this.checkDuplicated(duplicatedTag.oldText, duplicatedTag.text);
            if (!result) {
                this.onNotificationDuplicated(duplicatedTag.text, duplicatedTag.value);
            }
            else {
                this.dispatchChangeEvent(duplicatedTag.text, duplicatedTag.value);
                duplicatedTag.success();
            }
        };
        this.onNotificationDuplicated = (text, value) => {
            this.dispatchEvent(new CustomEvent('duplicated', { detail: { text, value } }));
        };
        this.dispatchChangeEvent = (tagName, value) => {
            this.dispatchEvent(new CustomEvent('change-item', { detail: { text: tagName, value: value } }));
        };
    }
    handleSlotChange() {
        // Check if the slot's render is complete
        this.updateComplete.then(() => {
            const tags = this.tagItems;
            if (tags.length > 0) {
                this._isLoaded = true;
                tags.forEach(obj => {
                    obj.removeEventListener('duplicated-check', this.addEvent);
                    obj.addEventListener('duplicated-check', this.addEvent);
                });
            }
        });
    }
    render() {
        return html `<slot name="item" @slotchange=${this.handleSlotChange}></slot>`;
    }
};
__decorate([
    queryAssignedElements({ slot: 'item', flatten: true })
], TNonDuplicateTagList.prototype, "tagItems", void 0);
__decorate([
    state()
], TNonDuplicateTagList.prototype, "_isLoaded", void 0);
TNonDuplicateTagList = __decorate([
    customElement(elementName)
], TNonDuplicateTagList);
export { TNonDuplicateTagList };

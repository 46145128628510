'use strict'

import Cookies from 'universal-cookie'

import { LoginAPI, LogoutAPI, SelectProfileAPI, TokenCheckAPI } from './data/auth-api'
import { ERROR_CODE_SESSION_EXPIRATION, IToken, ILoginRequest, ILoginResponse, ILogoutResponse, ITokenResponse, IProfile } from './types'
import { ACCESS_TOKEN, REFRESH_TOKEN } from './constants'

import { LOGIN_PAGE, FIND_PASSWORD_PAGE } from '~/service/router/router-service'

const SESSION_EXCLUSION_PAGE = [
  LOGIN_PAGE,
  FIND_PASSWORD_PAGE
]

export default class AuthService {
  /**
   * 로그인을 합니다.
   * @param params 로그인 Request
   */
  async login (params: ILoginRequest): Promise<void> {
    const response: ILoginResponse = await LoginAPI(params)
    if (response.code !== 0) {
      throw new Error(response.message)
    }
    response.data && this.setToken(response.data)
  }

  /**
   * 로그아웃을 합니다.
   */
  async logout (): Promise<void> {
    const response: ILogoutResponse = await LogoutAPI()
    if (response.code !== 0) {
      throw new Error(response.message)
    }

    this.clearToken()
  }

  /**
   * 접속자 정보를 가져옵니다.
   * @returns 접속자 정보
   */
  async getProfile (): Promise<IProfile> {
    const response = await SelectProfileAPI()
    if (response.code !== 0) {
      throw new Error(response.message)
    }

    return response.data.profile
  }

  /**
   * 세션을 검증합니다.
   * @param token 토큰
   */
  async checkedSession (config?: object): Promise<void> {
    const response: ITokenResponse = await TokenCheckAPI(config)
    if (response.code !== 0) {
      this.clearToken()
      throw new Error(response.message)
    }
    response.data && this.setToken(response.data)
  }

  /**
   * 세션을 검증합니다.
   * @param headerCookie 쿠키 문자열
   */
  async checkedSessionFromHeaderCookie (headerCookie: string): Promise<void> {
    const token: IToken = this.getSigninTokenFromHeaderCookie(headerCookie)
    if (token.accessToken === '') {
      throw new Error('세션이 만료되었습니다.')
    }

    const config = {
      headers: {
        [ACCESS_TOKEN]: token.accessToken,
        [REFRESH_TOKEN]: token.refreshToken
      }
    }

    await this.checkedSession(config)
  }

  /**
   * 세션이 만료되었는지 여부를 확인합니다.
   * @param errorCode 에러 코드
   * @returns 로그인 세션 만료 여부
   */
  isSessionExpiration (errorCode: number | undefined): boolean {
    return errorCode === ERROR_CODE_SESSION_EXPIRATION
  }

  /**
   * path가 세션 제외 페이지인지 검사합니다.
   * @param path 검사할 path
   * @returns true : 세션 검사 제외 페이지, false: 세션 검사 포함 페이지
   */
  checkedSessionExclusionPage (path: string): boolean {
    return SESSION_EXCLUSION_PAGE.includes(path)
  }

  /**
   * 세션 만료 시 로그인 페이지로 이동시킵니다.
   * @param context Context
   */
  navigateToSessionExpiration () : string {
    return `${LOGIN_PAGE}?errorCode=${ERROR_CODE_SESSION_EXPIRATION}`
  }

  /**
   * 토큰을 설정합니다.
   * @param token 토큰
   */
  private setToken (token: IToken): void {
    const cookies = new Cookies()
    cookies.set(ACCESS_TOKEN, token.accessToken, { path: '/' })
    cookies.set(REFRESH_TOKEN, token.refreshToken, { path: '/' })
  }

  /**
   * 토큰을 제거합니다.
   */
  clearToken (): void {
    const cookies = new Cookies()
    cookies.remove(ACCESS_TOKEN, { path: '/' })
    cookies.remove(REFRESH_TOKEN, { path: '/' })
  }

  /**
   * 로그인 header token 쿠키 가져오기
   * ssr 인경우 header값에서 읽어들여 가져옵니다
   *
   * @category token
   */
  protected getSigninTokenFromHeaderCookie = (headerCookie: string): IToken => {
    const accessToken = this.getCookieHeader(headerCookie, ACCESS_TOKEN) ?? ''
    const refreshToken = this.getCookieHeader(headerCookie, REFRESH_TOKEN) ?? ''
    return {
      accessToken,
      refreshToken
    }
  }

  private getCookieHeader = (cookie: string, name: string): string | null => {
    const match = cookie.match(new RegExp(`(^| )${name}=([^;]+)`))
    if (match) {
      return match[2]
    }
    return null
  }
}

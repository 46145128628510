import { Plugin } from '@nuxt/types'
import Vue from 'vue'
import Snackbar from './Snackbar.vue'

declare module '@nuxt/types' {
  interface Context {
    $snackbar: any
  }
}

const snackbarPlugin: Plugin = (context) => {
  const SnackbarEl = Vue.extend(Snackbar)
  const vm: any = new SnackbarEl().$mount()
  document.body.appendChild(vm.$el)

  context.$snackbar = {
    success: (title: string) => vm.add('success', title),
    error: (title: string) => vm.add('error', title),
    info: (title: string) => vm.add('info', title)
  }
}

export default snackbarPlugin

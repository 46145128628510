import { render, staticRenderFns } from "./FormInput.vue?vue&type=template&id=5c9205ab&scoped=true"
import script from "./FormInput.vue?vue&type=script&setup=true&lang=ts"
export * from "./FormInput.vue?vue&type=script&setup=true&lang=ts"
import style0 from "@/assets/styles/input.css?vue&type=style&index=0&id=5c9205ab&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c9205ab",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Input: require('/apps/src/ound-admin/apps/admin/pages/sample/input.vue').default})

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.openDaumPostcode = exports.loadSdk = void 0;
/**
 * 다음 주소 sdk를 호출합니다
 * sdk링크: https://postcode.map.daum.net/guide
 *
 * @category 다음주소검색
 * @example
 *
 * ```javascript
 * AddrDaumUtil.loadSdk();
 * ```
 *
 **/
const loadSdk = () => {
    return new Promise((resolve, reject) => {
        const js = document.createElement("script");
        js.src = "//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js";
        js.onload = resolve;
        document.head.append(js);
    });
};
exports.loadSdk = loadSdk;
/**
 * 다음 주소 검색창을 오픈
 *
 * @category 다음주소검색
 * @example
 *
 * ```javascript
 * const response = await AddrDaumUtil.openDaumPostcode();
 * ```
 * @return PostReturnData
 *
 **/
const openDaumPostcode = () => {
    return new Promise((resolve, reject) => {
        new window.daum.Postcode({
            oncomplete(data) {
                let extraRoadAddr = ""; // 참고 항목 변수
                if (data.buildingName !== "") {
                    extraRoadAddr = `(${data.buildingName})`;
                }
                const returnData = {
                    zonecode: data.zonecode,
                    roadAddress: data.roadAddress,
                    jibunAddress: data.jibunAddress,
                    extraRoadAddr: extraRoadAddr !== null && extraRoadAddr !== void 0 ? extraRoadAddr : ""
                };
                return resolve(returnData);
            }
        }).open();
    });
};
exports.openDaumPostcode = openDaumPostcode;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateUUID = void 0;
/**
 * uuid 생성
 *
 * @category 문자
 * @example
 * 36자의 문자열로 표시되는 128비트 숫자로, 정보를 고유하게 식별하는 데 사용합니다.
 *
 * ```javascript
 * const uuid = generateUUID();
 * ```
 *
 **/
const generateUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0;
        const v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
};
exports.generateUUID = generateUUID;


import { defineComponent, onBeforeMount, useContext, useRoute, reactive } from '@nuxtjs/composition-api'

import { FIND_PASSWORD_PAGE, HOME_PAGE } from '~/service/router/router-service'

import { ILoginRequest } from '~/service/auth/types'
import AuthService from '~/service/auth/auth-service'
import ValidatorService from '~/service/validator/validator-service'
import TabService from '~/service/global/tab-service'

export default defineComponent({
  layout: 'membership',
  middleware: ['not-authenticated'],
  setup () {
    const { $snackbar, redirect } = useContext()
    const route = useRoute()

    const authService: AuthService = new AuthService()
    const validatorService: ValidatorService = new ValidatorService()
    const tabService: TabService = new TabService()

    const id = reactive({ value: '', error: '' })
    const password = reactive({ value: '', error: '' })

    onBeforeMount(() => {
      const query = route.value.query
      const errorCode: number | undefined = parseInt(query.errorCode as string)
      if (authService.isSessionExpiration(errorCode)) {
        // $snackbar.error('로그인 세션이 만료되었습니다.')
        removeQueryString()

        authService.clearToken()
      }
    })

    const removeQueryString = () => {
      window.history.replaceState(null, '', window.location.pathname)
    }

    /**
     * HTML Element를 focus합니다.
     * @param el Focus 할 HTML Element
     */
    const focus = (el: HTMLElement | null) => {
      el && el.focus()
    }

    /**
     * 유효성 검사를 통해 로그인 가능 여부를 판단합니다.
     * @return : 로그인 가능 : ture, 로그인 불가능 : false
     */
    const checkedLogin = () => {
      id.error = validatorService.checkedId(id.value)
      password.error = validatorService.checkedPassword(password.value)

      if (id.error) {
        focus(document.querySelector('input[name=id]'))
      } else if (password.error) {
        focus(document.querySelector('input[name=password]'))
      }

      return !id.error && !password.error
    }

    /**
     * 로그인을 합니다.
     */
    const login = async () => {
      if (!checkedLogin()) {
        return
      }

      const params: ILoginRequest = {
        managerId: id.value,
        managerPwd: password.value
      }

      try {
        await authService.login(params)
        tabService.clear()

        redirect(HOME_PAGE)
      } catch (error: any) {
        $snackbar.error(error.message)
      }
    }

    return {
      FIND_PASSWORD_PAGE,
      id,
      password,
      login
    }
  }
})


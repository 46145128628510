'use strict'

import { PHASE_TYPE, MODE_TYPE } from './types'
import Config from '~/config/configuration'

export class Logger {
  readonly phase: PHASE_TYPE
  readonly mode: MODE_TYPE
  readonly isNotProd: boolean

  constructor () {
    this.phase = Config.PHASE as PHASE_TYPE
    this.mode = Number(Config.LOGGER_MODE) as MODE_TYPE
    this.isNotProd = this.phase !== PHASE_TYPE.PROD
  }

  log (message?: any, ...optionalParams: any[]) {
    if (MODE_TYPE.LOG >= this.mode) {
      console.log(
        this.isNotProd ? `[log] ${message}` : '',
        ...optionalParams
      )
    }
  }

  info (message?: any, ...optionalParams: any[]) {
    if (MODE_TYPE.INFO >= this.mode) {
      console.info(
        this.isNotProd ? `[info] ${message}` : '',
        ...optionalParams
      )
    }
  }

  warn (message?: any, ...optionalParams: any[]) {
    if (MODE_TYPE.WARN >= this.mode) {
      console.warn(
        this.isNotProd ? `[warn] ${message}` : '',
        ...optionalParams
      )
    }
  }

  error (message?: any, ...optionalParams: any[]) {
    if (MODE_TYPE.ERROR >= this.mode) {
      console.error(
        this.isNotProd ? `[error] ${message}` : '',
        ...optionalParams
      )
    }
  }
}

import { render, staticRenderFns } from "./input.vue?vue&type=template&id=1375c65b&scoped=true"
import script from "./input.vue?vue&type=script&setup=true&lang=ts"
export * from "./input.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./input.vue?vue&type=style&index=0&id=1375c65b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1375c65b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormInput: require('/apps/src/ound-admin/apps/admin/components/form/FormInput.vue').default})

export const prefixName = "tms";
export const BUTTON_COLOR_TYPE = {
    primary: 'primary',
    ty1: 'ty1',
    ty2: 'ty2',
};
export const VARIANT_TYPE = {
    line: 'line'
};
export const ICON_TYPE = {
    search: 'search',
    arrow: 'arrow',
    reload: 'reload'
};

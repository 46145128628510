import { render, staticRenderFns } from "./login.vue?vue&type=template&id=740dba28"
import script from "./login.vue?vue&type=script&lang=ts"
export * from "./login.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormInput: require('/apps/src/ound-admin/apps/admin/components/form/FormInput.vue').default})

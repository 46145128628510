import { Module, VuexModule, Mutation } from 'vuex-module-decorators'

import { Tab } from '~/service/global/types'

const MAX_TAB_COUNT: number = 10
const MIN_TAB_COUNT: number = 1

@Module({
  name: 'tabStore',
  stateFactory: true, // nuxt에서는 팩토리 함수 생성 필요
  namespaced: true
})
export default class TabStore extends VuexModule {
  _tabList: Tab[] = []
  _currentTab: string | undefined = undefined

  get tabList () {
    return [...this._tabList]
  }

  get currentTab () {
    return this._currentTab
  }

  get currentTabInfo (): Tab | undefined {
    return this._tabList.find((tab: Tab) => tab.id === this._currentTab)
  }

  get getTabById () {
    return (id: string) => {
      return this._tabList.find((tabInfo: Tab) => tabInfo.id === id)
    }
  }

  get getTabByURL () {
    return (url: string) => {
      return this._tabList.find((tabInfo: Tab) => tabInfo.url === url)
    }
  }

  get isAdded () {
    return this._tabList.length < MAX_TAB_COUNT
  }

  get isRemoved () {
    return this._tabList.length > MIN_TAB_COUNT
  }

  @Mutation
  clear () {
    this._tabList = []
    this._currentTab = undefined
  }

  @Mutation
  set_tabList (_tabList: Tab[]) {
    this._tabList = _tabList
  }

  @Mutation
  setCurrentTab (id: string) {
    this._currentTab = id
  }

  @Mutation
  addTab (tab: Tab) {
    this._tabList.push(tab)
  }

  @Mutation
  removeTab (id: string) {
    const index: number = this._tabList.findIndex(
      (tabInfo: Tab) => tabInfo.id === id
    )
    if (index > -1) {
      this._tabList.splice(index, 1)

      const focusIndex =
        index < this._tabList.length ? index : this._tabList.length - 1
      const tab: Tab | undefined = this._tabList.at(focusIndex)
      if (tab) {
        this._currentTab = tab.id
      }
    }
  }
}

import axios from 'axios'
import { axiosInstance } from '~/plugins/axios'
export { AxiosResponse } from '~/plugins/axios'

/* eslint-disable no-use-before-define */
class AxiosHelper {
  private static instance: AxiosHelper

  axios: any

  private constructor () {
    this.axios = axiosInstance
  }

  static getInstance (): AxiosHelper {
    if (!AxiosHelper.instance) {
      AxiosHelper.instance = new AxiosHelper()
    }

    return AxiosHelper.instance
  }

  getToken () {
    return axios.CancelToken
  }

  async get (path: string, query?: object, options?: object) {
    return await axiosInstance.get(path, { params: query, ...options })
  }

  async post (path: string, data?: object, options?: object) {
    return await axiosInstance.post(path, data, { ...options })
  }

  multiPart (path: string, data?: object, options?: object) {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      ...options
    }

    return this.post(path, data, config)
  }

  async put (path: string, data?: object, options?: object) {
    return await axiosInstance.put(path, data, { ...options })
  }

  async patch (path: string, data?: object, options?: object) {
    return await axiosInstance.patch(path, data, { ...options })
  }

  async delete (path: string, data?: object, options?: object) {
    if (typeof data === 'undefined') {
      return await this.deleteNoData(path, options)
    } else {
      return await this.deleteWithData(path, data, options)
    }
  }

  private async deleteWithData (path: string, data?: object, options?: object) {
    return await axiosInstance.delete(path, { data, ...options })
  }

  private async deleteNoData (path: string, options?: object) {
    return await axiosInstance.delete(path, { ...options })
  }
}

const axiosHelper: AxiosHelper = AxiosHelper.getInstance()
export default axiosHelper

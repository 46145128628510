"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InputCondition = exports.OnlyText = void 0;
const FORMAT_TYPE = {
    email: "email",
    phoneDash: "phoneDash"
};
const REG_GROUP = {
    number: "0-9",
    ko: "ㄱ-ㅎ|ㅏ-ㅣ|가-힣|ㆍᆢ",
    en: "a-zA-Z",
    spec: "~!@#$%;:^&*\\(\\)-=_+{}\\[\\]<>,./?'\"",
    space: "\\s"
};
const REG_FORMAT_GROUP = {
    email: "0-9a-zA-Z@._-",
    phoneDash: "0-9-"
};
class OnlyText {
    constructor(binding) {
        /**
         * 숫자, 한글, 영문, 특수문자, 공백일경우 정규식 가져오기
         * isFormat false 일경우에만 허용
         *
         */
        this.getRegExp = () => {
            const regxArray = [];
            if (this.binding.isNumber) {
                regxArray.push(REG_GROUP.number);
            }
            if (this.binding.isKo) {
                regxArray.push(REG_GROUP.ko);
            }
            if (this.binding.isEn) {
                regxArray.push(REG_GROUP.en);
            }
            if (this.binding.isSpec) {
                const allowSpec = this.binding.allowSpec;
                if (typeof allowSpec !== "undefined" && allowSpec !== "") {
                    regxArray.push(`\\${allowSpec}`);
                }
                else {
                    regxArray.push(`\\${REG_GROUP.spec}`);
                }
            }
            if (this.binding.isSpace) {
                regxArray.push(REG_GROUP.space);
            }
            return `[^${regxArray.join("|")}]`; // result: [^0-9|a-zA-Z|(~!@#$%^&*\(\)-=_+{}\[\]<>,./?'")|\s]
        };
        /**
         * 입력폼에 제한할 문자열 정규식
         * isFormat true 일경우에는 다른 옵션은 모두 무시됨
         *
         */
        this.getRepalceRegx = () => {
            let regxString = "";
            if (this.binding.isFormat) {
                regxString = this.getFormatRegExp(this.binding.formatType);
            }
            else {
                regxString = this.getRegExp();
            }
            return new RegExp(regxString, "g");
        };
        /** 문자열 치환 */
        this.replaceText = (text) => text.replace(this.regx, "");
        /** 숫자 키패드 노출 여부 판단
         * 1. isNumber가 true일 경우
         * 2. isFormat true 이고 formatType phoneDash인 경우
         */
        this.hasNumberKeypad = () => {
            const values = this.binding;
            const hasTrueValueCount = Object.values(values).filter((obj) => obj === true).length;
            //isNumber만 true 일경우 숫자 키패드
            if (hasTrueValueCount === 1 && values.isNumber)
                return true;
            if (values.isFormat && values.formatType === FORMAT_TYPE.phoneDash)
                return true;
            return false;
        };
        /** 이메일 키패드 노출 여부 판단
         * isFormat true 이고 formatType email인 경우만 숫자키패드 적용
         */
        this.hasEmailKeypad = () => {
            const values = this.binding;
            if (values.isFormat && values.formatType === FORMAT_TYPE.email) {
                return true;
            }
            return false;
        };
        /** 글자 input 이벤트 callback */
        this.onChangeEvent = (event) => {
            const target = event.target;
            const initalValue = target.value;
            target.value = this.replaceText(initalValue);
            if (initalValue !== target.value) {
                event.stopPropagation();
            }
        };
        this.binding = binding;
        this.regx = this.getRepalceRegx();
    }
    /**
     * 이메일, dash 핸드폰일 경우 해당 정규식 가져오기
     * isFormat true 일경우에는 다른 옵션은 모두 무시됨
     *
     */
    getFormatRegExp(formatType) {
        const formatRegx = REG_FORMAT_GROUP[formatType];
        return `[^${formatRegx}]`;
    }
    /** input 속성 추가 */
    addAttribute(el) {
        if (this.hasNumberKeypad()) {
            el.setAttribute("inputmode", "numeric");
            el.setAttribute("pattern", "[0-9]*");
        }
        if (this.hasEmailKeypad()) {
            el.setAttribute("type", "email");
        }
    }
    /** input 속성 삭제 */
    removeAttribute(el) {
        if (this.hasNumberKeypad()) {
            el.removeAttribute("inputmode");
            el.removeAttribute("pattern");
        }
    }
}
exports.OnlyText = OnlyText;
/** event bind */
const inserted = (el, binding) => {
    const value = binding.value;
    const onlyText = new OnlyText(value);
    el.addEventListener("input", onlyText.onChangeEvent, { passive: true });
    onlyText.addAttribute(el);
};
const unbind = (el, binding) => {
    const value = binding.value;
    const onlyText = new OnlyText(value);
    el.removeEventListener("input", onlyText.onChangeEvent);
    onlyText.removeAttribute(el);
};
exports.InputCondition = {
    inserted,
    unbind
};
exports.default = exports.InputCondition;

import { Plugin } from '@nuxt/types'
import { Logger } from './logger'

const logger: Logger = new Logger()

declare module '@nuxt/types' {
  interface Context {
    $logger: Logger
  }
}

const loggerPlugin: Plugin = (context) => {
  context.$logger = logger
}

export default loggerPlugin

'use strict'

import { v4 as uuidv4 } from 'uuid'
import { TAB_TYPE, Tab } from './types'
import { TAB_INFO } from './tab-route'
import { tabStore } from '~/store'

const NOT_DEFINE_PAGE: string = 'NotDefineComponentPage'

export default class TabService {
  /**
   * 탭 객체를 생성합니다.
   * @param url 탭과 연결할 url를 정의합니다.
   * @param title 탭의 제목을 정의합니다.
   * @param menuSeq 탭과 연결할 메뉴의 번호를 정의합니다.
   */
  createTab (url: string, type: number, title: string, menuSeq: number | string, option?: any): Tab {
    return {
      id: uuidv4(),
      type,
      title,
      url,
      menuSeq,
      option
    }
  }

  /**
   * 탭 목록의 존재 여부를 확인합니다.
   * @returns true : 탭 있음, false : 탭 없음
   */
  isTabList (): boolean {
    return tabStore.tabList.length > 0
  }

  /**
   * 탭 목록을 반환합니다.
   * @returns 반환할 탭 목록
   */
  tabList (): Tab[] {
    return tabStore.tabList
  }

  /**
   * 탭이 열려있는지 확인합니다.
   * @param url 탭 연결된 url
   * @returns
   */
  checkedOpenTab (url: string): Tab | undefined {
    return tabStore.getTabByURL(url)
  }

  /**
   * List Type의 Tab을 오픈합니다.
   * @param tab 오픈할 탭 정보
   * @param error 에러 반환 콜백함수
   */
  openListTab (url: string, title: string, menuSeq: number | string, option?: any) {
    this.openTab(
      this.createTab(url, TAB_TYPE.LIST, title, menuSeq, option)
    )
  }

  /**
   * Register Type의 Tab을 오픈합니다.
   * @param tab 오픈할 탭 정보
   * @param error 에러 반환 콜백함수
   */
  openRegisterTab (url: string, title: string, menuSeq: number | string, option?: any) {
    this.openTab(
      this.createTab(url, TAB_TYPE.REGISTER, title, menuSeq, option)
    )
  }

  /**
   * Detail Type의 Tab을 오픈합니다.
   * @param tab 오픈할 탭 정보
   * @param error 에러 반환 콜백함수
   */
  openDetailTab (url: string, title: string, menuSeq: number | string, option?: any) {
    this.openTab(
      this.createTab(url, TAB_TYPE.DETAIL, title, menuSeq, option)
    )
  }

  /**
   * 탭을 오픈합니다.
   * @param tab 오픈할 탭 정보
   * @param error 에러 반환 콜백함수
   */
  openTab (tab: Tab) {
    const checkedTab = this.checkedOpenTab(tab.url)
    if (checkedTab) {
      tabStore.setCurrentTab(checkedTab.id)
    } else if (!tabStore.isAdded) {
      throw new Error('탭은 10개까지만 사용할 수 있습니다')
    } else {
      tabStore.addTab(tab)
      tabStore.setCurrentTab(tab.id)
    }
  }

  /**
   * 탭을 닫습니다.
   * @param id 닫을 탭 ID
   * @param error 에러 반환 콜백함수
   */
  closeTab (id: string) {
    if (!tabStore.isRemoved) {
      throw new Error('마지막 탭은 삭제할 수 없습니다')
    }

    const tab: Tab | undefined = tabStore.getTabById(id)
    if (tab) {
      tabStore.removeTab(tab.id)
    }
  }

  /**
   * 현재 열린 탭의 정보를 반환합니다.
   * @returns 현재 열린 탭
   */
  currentTab (): Tab | undefined {
    return tabStore.currentTabInfo
  }

  /**
   * 탭의 선택합니다.
   * @param id 선택할 탭의 Id
   */
  setCurrentTab (id: string) {
    tabStore.setCurrentTab(id)
  }

  /**
   * 현재 탭을 닫습니다.
   */
  closeCurrentTab () {
    const tab = this.currentTab()
    if (tab) {
      this.closeTab(tab.id)
    }
  }

  /**
   * 현재 열린 탭의 연결된 컴포넌트 이름을 반환해줍니다.
   * @returns 현재 열린 탭의 연결된 컴포넌트 이름
   */
  getCurrentTabConnectionComponent () {
    return this.getTabConnectionComponent(this.currentTab()?.url)
  }

  /**
   * URL에 연결할 정의된 컴포넌트 이름을 반환해줍니다.
   * 컴포넌트의 정의를 추가하기 위해서는 types 파일에 선언해야합니다.
   * @param url 컴포넌트 이름을 찾을 연결된 URL
   * @returns 연결된 컴포넌트 이름
   */
  getTabConnectionComponent (url: string | undefined) {
    if (!url) {
      return NOT_DEFINE_PAGE
    }

    const path = url.split('?')[0]
    const result = Object.values(TAB_INFO).find(
      urlInfo => urlInfo.URL === path
    )
    return result ? result.VIEW : NOT_DEFINE_PAGE
  }

  /**
   * 탭 정보를 초기화합니다.
   */
  clear () {
    tabStore.clear()
  }
}

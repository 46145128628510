// https://www.npmjs.com/package/vuex-persist
// eslint-disable-next-line import/no-named-as-default
import VuexPersistence from 'vuex-persist'

export default ({ store }: { store: any }) => {
  if (process.browser) {
    new VuexPersistence({
      storage: window.localStorage,
      modules: ['sampleStorage', 'menuStore', 'tabStore']
    }).plugin(store)
  }
}

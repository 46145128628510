// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/ico_close_white.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/ico_success.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/images/ico_info.svg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../assets/images/ico_error.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".snackbar_area[data-v-7464b996]{bottom:96px;left:calc(50% - 244px);position:fixed;z-index:600}.snackbar[data-v-7464b996]{align-items:center;border-radius:12px;box-sizing:border-box;display:flex;margin-top:10px;min-height:56px;padding:0 16px;position:relative;width:488px}.snackbar[data-v-7464b996]:after{content:\"\";display:block;height:24px;left:16px;position:absolute;top:calc(50% - 12px);width:24px}.snackbar .txt[data-v-7464b996]{color:#fff;font-weight:500;padding:10px 40px;position:relative}.snackbar .btn_close[data-v-7464b996]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 50% no-repeat;display:block;height:32px;position:absolute;right:12px;top:calc(50% - 16px);width:32px}.snackbar.success[data-v-7464b996]{background-color:#4276ff;box-shadow:0 6px 12px rgba(11,59,180,.25)}.snackbar.success[data-v-7464b996]:after{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") 0 no-repeat}.snackbar.info[data-v-7464b996]{background-color:#373c3d;box-shadow:0 6px 12px rgba(0,0,0,.25)}.snackbar.info[data-v-7464b996]:after{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") 0 no-repeat}.snackbar.error[data-v-7464b996]{background-color:#ff4545;box-shadow:0 6px 12px rgba(255,69,69,.25)}.snackbar.error[data-v-7464b996]:after{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") 0 no-repeat}.list-enter-active[data-v-7464b996],.list-leave-active[data-v-7464b996],.list-move[data-v-7464b996]{transition:all .5s ease}.list-enter-from[data-v-7464b996],.list-leave-to[data-v-7464b996]{opacity:0;transform:translateY(0)}.list-leave-active[data-v-7464b996]{position:absolute}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;

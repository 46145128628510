var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var TTagElement_1;
import { html, css, LitElement } from "lit";
import { styleMap } from 'lit/directives/style-map.js';
import { customElement, property, state, query } from "lit/decorators.js";
import { iconRemove } from "./svg";
import { REG_GROUP } from "./types";
import { prefixName } from "../types/types";
const tagType = 'tag';
const elementName = `${prefixName}-${tagType}`;
let TTagElement = TTagElement_1 = class TTagElement extends LitElement {
    constructor() {
        super();
        //태그명
        this.tag = "";
        //태그 custom value
        this.value = "";
        //수정 가능 여부
        this.disabled = false;
        //삭제 가능 여부
        this.hasRemove = "true";
        //태그 입력폼 제한
        this.inputCondition = "";
        //태그 스타일 맵
        this.tagStyles = {};
        this.fontStyles = TTagElement_1.defaultFontStyle;
        //태그 수정 불가 스타일 맵
        this.disabledStyles = TTagElement_1.defaultDisabledStyle;
        this._isLoaded = false;
        this._isEdit = false;
        this._tagName = "";
        this._inputWidthSize = 8;
        this._inputRegx = new RegExp('');
        this.getRepalceRegx = () => {
            if (this.isEmpty(this.inputCondition)) {
                return new RegExp('');
            }
            const options = JSON.parse(this.inputCondition);
            const regxArray = [];
            if (options.isNumber) {
                regxArray.push(REG_GROUP.number);
            }
            if (options.isKo) {
                regxArray.push(REG_GROUP.ko);
            }
            if (options.isEn) {
                regxArray.push(REG_GROUP.en);
            }
            if (options.isSpec) {
                const allowSpec = options.allowSpec;
                if (typeof allowSpec !== "undefined" && allowSpec !== "") {
                    regxArray.push(`\\${allowSpec}`);
                }
                else {
                    regxArray.push(`\\${REG_GROUP.spec}`);
                }
            }
            if (options.isSpace) {
                regxArray.push(REG_GROUP.space);
            }
            return new RegExp(`[^${regxArray.join("|")}]`, "g");
        };
        this.repalceInputText = (event) => {
            const target = event.target;
            const initalValue = target.value;
            target.value = initalValue.replace(this._inputRegx, "");
        };
        this.calculateInputWidthSize = () => {
            var _a, _b;
            const text = this.input.value;
            let fontSize = TTagElement_1.defaultFontStyle.fontSize;
            if (typeof ((_a = this.fontStyles) === null || _a === void 0 ? void 0 : _a.fontSize) !== "undefined") {
                fontSize = (_b = this.fontStyles) === null || _b === void 0 ? void 0 : _b.fontSize;
            }
            const tempElement = document.createElement('span');
            tempElement.style.fontSize = fontSize;
            tempElement.style.visibility = 'hidden';
            tempElement.innerHTML = text;
            document.body.appendChild(tempElement);
            const width = tempElement.getBoundingClientRect().width;
            document.body.removeChild(tempElement);
            return width + 2;
        };
        //스타일맵 가져오기
        this.getTagStyleMap = () => {
            const defaultStyle = Object.assign({}, TTagElement_1.defaultFontStyle);
            if (this.disabled) {
                return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, defaultStyle), this.tagStyles), this.fontStyles), TTagElement_1.defaultDisabledStyle), this.disabledStyles);
            }
            return Object.assign(Object.assign(Object.assign({}, defaultStyle), this.tagStyles), this.fontStyles);
        };
        this.isEmpty = (obj) => {
            if (typeof obj === "undefined" ||
                obj === null ||
                obj.length === 0 ||
                obj === "" ||
                !/[^\s]/.test(obj) ||
                /^\s*$/.test(obj)) {
                return true;
            }
            else {
                return false;
            }
        };
        //input ui로 변경 click event
        this.changeUiInput = () => {
            this._isEdit = true;
        };
        //태그 ui로 변경 blur 이벤트
        this.changeUiTag = (e) => {
            const inputValue = this.input.value;
            if (this.isEmpty(inputValue)) {
                this.input.focus();
                return;
            }
            ;
            this._isEdit = false;
            this._tagName = inputValue;
        };
        this.inputTagKeydown = (event) => {
            const { key } = event;
            if (key === 'Enter') {
                this.changeUiTag(event);
            }
        };
        this.tagInput = (event) => {
            if (typeof event !== "undefined") {
                this.repalceInputText(event);
            }
            this._inputWidthSize = this.calculateInputWidthSize();
        };
        this.renderTagUi = () => {
            return html `<em class="tms-tag-name" @click=${() => this.changeUiInput()}>${this._tagName}</em>`;
        };
        this.renderDisplayTagUi = () => html `${this._tagName}`;
        this.renderInputTagUi = () => {
            return html `<input type='text' @input=${this.tagInput} @blur=${this.changeUiTag} @keyup=${this.inputTagKeydown} .value="${this._tagName}"  @change="${this.onChangeInputTag}" style=${styleMap(Object.assign(Object.assign({}, this.fontStyles), { width: `${this._inputWidthSize}px` }))} />`;
        };
        this.renderUi = () => {
            if (this._isEdit) {
                return this.renderInputTagUi();
            }
            if (this.disabled) {
                return this.renderDisplayTagUi();
            }
            return this.renderTagUi();
        };
        this.renderButtonRemove = () => {
            if (!JSON.parse(this.hasRemove))
                return html ``;
            return html `<button class="button-remove" @click="${this.onRemoveTag}">${iconRemove}</button>`;
        };
        //input tag 변경 이벤트
        this.onChangeInputTag = () => {
            if (this._tagName !== this.input.value) {
                this.dispatchChangeEvent();
            }
        };
        this.dispatchChangeEvent = () => {
            this.dispatchEvent(new CustomEvent('change', { detail: { text: this.input.value, value: this.value }, bubbles: false, composed: false }));
        };
        this.onRemoveTag = () => {
            this.dispatchEvent(new CustomEvent('remove', { detail: { text: this._tagName, value: this.value }, bubbles: true, composed: true }));
        };
    }
    get tagName() {
        return this._tagName;
    }
    connectedCallback() {
        super.connectedCallback();
        if (!this._isLoaded) {
            this._isEdit = false;
            this._tagName = this.tag;
            this._isLoaded = true;
            this._inputRegx = this.getRepalceRegx();
        }
    }
    updated(changedProperties) {
        if (changedProperties.has('_isEdit') && this._isEdit) {
            this.tagInput();
            this.input.focus();
        }
    }
    render() {
        return html `
      <div class="tag">
        <slot></slot>
        <span style=${styleMap(this.getTagStyleMap())}>
          ${this.renderUi()}
        </span>
        ${this.renderButtonRemove()}
      </div>
      `;
    }
};
TTagElement.defaultFontStyle = {
    fontSize: '14px',
    color: '#161616'
};
TTagElement.defaultDisabledStyle = {
    backgroundColor: '#E8EDF3',
};
TTagElement.styles = css `
  input[type="text"]{
      background-color: transparent;
      border: none;
      outline: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
  }
  .tag {
    border-radius: 4px;
    background-color: #E8EDF3;
    display: inline-block;
    padding: 3px;
    margin: 0 8px 6px 0;
    box-sizing: border-box;
  }
  .tag em {font-style: normal}

  .button-remove {
    min-width: 24px;
    height: 100%;
    border:0px;
    background-color: transparent;
    cursor: pointer;
  }
`;
__decorate([
    property({ type: String })
], TTagElement.prototype, "tag", void 0);
__decorate([
    property({ type: String, reflect: true })
], TTagElement.prototype, "value", void 0);
__decorate([
    property({ type: Boolean })
], TTagElement.prototype, "disabled", void 0);
__decorate([
    property({ type: String, attribute: 'has-remove' })
], TTagElement.prototype, "hasRemove", void 0);
__decorate([
    property({ type: String, attribute: 'input-condition', reflect: true })
], TTagElement.prototype, "inputCondition", void 0);
__decorate([
    property({ type: Object, attribute: 'tag-styles' })
], TTagElement.prototype, "tagStyles", void 0);
__decorate([
    property({ type: Object, attribute: 'font-styles' })
], TTagElement.prototype, "fontStyles", void 0);
__decorate([
    property({ type: Object, attribute: 'disabled-styles' })
], TTagElement.prototype, "disabledStyles", void 0);
__decorate([
    state()
], TTagElement.prototype, "_isLoaded", void 0);
__decorate([
    state()
], TTagElement.prototype, "_isEdit", void 0);
__decorate([
    state()
], TTagElement.prototype, "_tagName", void 0);
__decorate([
    state()
], TTagElement.prototype, "_inputWidthSize", void 0);
__decorate([
    state()
], TTagElement.prototype, "_inputRegx", void 0);
__decorate([
    query('input')
], TTagElement.prototype, "input", void 0);
TTagElement = TTagElement_1 = __decorate([
    customElement(elementName)
], TTagElement);
export { TTagElement };

import { Module, VuexModule, Mutation } from 'vuex-module-decorators'

import { MenuUUID } from '~/service/menu/types'

@Module({
  name: 'menuStore',
  stateFactory: true, // nuxt에서는 팩토리 함수 생성 필요
  namespaced: true
})
export default class MenuStore extends VuexModule {
  _menuExpanedList: MenuUUID[] = []
  _isWideMode: boolean = false

  get menuExpanedList () {
    return this._menuExpanedList
  }

  get isWideMode () {
    return this._isWideMode
  }

  @Mutation
  menuExpaned (menuSeq: MenuUUID) {
    const index: number = this._menuExpanedList.findIndex(
      (uuid: MenuUUID) => uuid === menuSeq
    )
    if (index > -1) {
      this._menuExpanedList.splice(index, 1)
    } else {
      this._menuExpanedList.push(menuSeq)
    }
  }

  @Mutation
  clear () {
    this._menuExpanedList = []
  }

  @Mutation
  toggleSidebar () {
    this._isWideMode = !this._isWideMode
  }
}

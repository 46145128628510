var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, css, LitElement } from "lit";
import { classMap } from 'lit/directives/class-map.js';
import { customElement, property } from "lit/decorators.js";
import { iconSearch, iconArrow, iconReload } from "./svg";
import { prefixName, BUTTON_COLOR_TYPE, VARIANT_TYPE, ICON_TYPE } from "./types";
const buttonType = 'button';
const elementName = `${prefixName}-${buttonType}`;
let TButtonElement = class TButtonElement extends LitElement {
    constructor() {
        super(...arguments);
        this.disabled = false;
        this.href = "";
        this.target = "";
        this.variant = null;
        this.class = '';
        this.prefixIcon = null;
        this.suffixIcon = null;
        this.icon = null;
    }
    get _prefixIconFile() {
        return this.iconSvgFile(this.prefixIcon);
    }
    get _suffixIconFile() {
        return this.iconSvgFile(this.suffixIcon);
    }
    get _iconFile() {
        return this.iconSvgFile(this.icon);
    }
    iconSvgFile(icon) {
        switch (icon) {
            case ICON_TYPE.search:
                return iconSearch;
            case ICON_TYPE.arrow:
                return iconArrow;
            case ICON_TYPE.reload:
                return iconReload;
        }
        return '';
    }
    ;
    getButtonClassMap() {
        const classes = {};
        if (this.class) {
            classes[this.class] = true;
        }
        return classMap(classes);
    }
    getButtonInnerElement() {
        var _a;
        return html `
      ${this.prefixIcon ? html `<span class="mr5">${this._prefixIconFile}</span>` : ''}
      ${(_a = this._iconFile) !== null && _a !== void 0 ? _a : ''}
      <slot></slot>
      ${this.suffixIcon ? html `<span class="ml5">${this._suffixIconFile}</span>` : ''}
    `;
    }
    render() {
        if (this.href) {
            return html `
        <a href=${this.href} target=${this.target} ?disabled=${this.disabled} class=${this.getButtonClassMap()}>
          ${this.getButtonInnerElement()}
        </a>
      `;
        }
        else {
            return html `
        <button ?disabled=${this.disabled} class=${this.getButtonClassMap()}>
          ${this.getButtonInnerElement()}
        </button>
      `;
        }
    }
};
TButtonElement.styles = css `
      .mr5 { margin-right: 5px; }
      .ml5{ margin-left: 5px; }
      button, a {
        background: transparent;
        text-decoration: none;
        text-align: center;
        border:none;
        width:100%;
        font-size: inherit;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items:center;
        line-height:var(--tms-button-font-size);
        height: var(--tms-button-height);
        padding: var(--tms-button-padding);
        cursor: pointer;
        color: var(--tms-button-color);
        font-size: var(--tms-button-font-size);
        font-weight: var(--tms-button-font-weight);
        font-family: var(--tms-button-font-family), sans-serif;
      }
      ::slotted(*){
        color: var(--tms-button-color);
        font-size: var(--tms-button-font-size);
        font-weight: var(--tms-button-font-weight);
        font-family: var(--tms-button-font-family), sans-serif;
      }

      :host {
        display: inline-flex;
        cursor: pointer;
        vertical-align: middle;
        background: var(--tms-button-background);
        border: var(--tms-button-border);
        border-radius: var(--tms-button-radius);
        box-shadow: var(--tms-button-shadow);
        transition: var(--tms-button-transition); 
      }
      :host([variant=line]) {
        background: #fff;
      }
      :host(:hover),
      :host(:focus) {
        background: var(--tms-button-hover-background-color);
        color: var(--tms-button-hover-color)
      }
      :host svg {
        fill: var(--tms-button-icon-color);
        vertical-align:middle;
      }
      :host-context(button:hover, a:hover),
      :host-context(button:focus, a:focus) {
        color: var(--tms-button-hover-color)
      }
      :host([disabled]) {
        opacity: 0.5;
        pointer-events: none;
      }


      :host(.ty1) {
        background: var(--tms-button-background-ty1);
        border: var(--tms-button-border-ty1);
        box-shadow: var(--tms-button-shadow-ty1);
      }
      :host(.ty1:hover),
      :host(.ty1:focus) {
        background: var(--tms-button-hover-background-color-ty1);
      }
      :host(.ty1) svg {
        fill: var(--tms-button-icon-color-ty1);
      }
      button.ty1, a.ty1, button.ty1 ::slotted(*) {
        color: var(--tms-button-color-ty1);
      }
      button.ty1:hover, a.ty1:hover,
      button.ty1:focus, a.ty1:focus {
        color: var(--tms-button-hover-color-ty1)
      }


      :host(.ty2) {
        background: var(--tms-button-background-ty2);
        border: var(--tms-button-border-ty2);
        box-shadow: var(--tms-button-shadow-ty2);
      }
      :host(.ty2:hover),
      :host(.ty2:focus) {
        background: var(--tms-button-hover-background-color-ty2);
      }
      :host(.ty2) svg {
        fill: var(--tms-button-icon-color-ty2);
      }
      button.ty2, a.ty2, button.ty2 ::slotted(*) {
        color: var(--tms-button-color-ty2);
      }
      button.ty2:hover, a.ty2:hover,
      button.ty2:focus, a.ty2:focus {
        color: var(--tms-button-hover-color-ty2)
      }

      :host(.ty3) {
        background: var(--tms-button-background-ty3);
        border: var(--tms-button-border-ty3);
        box-shadow: var(--tms-button-shadow-ty3);
      }
      :host(.ty3:hover),
      :host(.ty3:focus) {
        background: var(--tms-button-hover-background-color-ty3);
      }
      :host(.ty3) svg {
        fill: var(--tms-button-icon-color-ty3);
      }
      button.ty3, a.ty3, button.ty3 ::slotted(*) {
        color: var(--tms-button-color-ty3);
      }
      button.ty3:hover, a.ty3:hover,
      button.ty3:focus, a.ty3:focus {
        color: var(--tms-button-hover-color-ty3)
      }

      :host(.ty4) {
        background: var(--tms-button-background-ty4);
        border: var(--tms-button-border-ty4);
        box-shadow: var(--tms-button-shadow-ty4);
      }
      :host(.ty4:hover),
      :host(.ty4:focus) {
        background: var(--tms-button-hover-background-color-ty4);
      }
      :host(.ty4) svg {
        fill: var(--tms-button-icon-color-ty4);
      }
      button.ty4, a.ty4, button.ty4 ::slotted(*) {
        color: var(--tms-button-color-ty4);
      }
      button.ty4:hover, a.ty4:hover,
      button.ty4:focus, a.ty4:focus {
        color: var(--tms-button-hover-color-ty4)
      }

      :host(.ty5) {
        background: var(--tms-button-background-ty5);
        border: var(--tms-button-border-ty5);
        box-shadow: var(--tms-button-shadow-ty5);
      }
      :host(.ty5:hover),
      :host(.ty5:focus) {
        background: var(--tms-button-hover-background-color-ty5);
      }
      :host(.ty5) svg {
        fill: var(--tms-button-icon-color-ty5);
      }
      button.ty5, a.ty5, button.ty5 ::slotted(*) {
        color: var(--tms-button-color-ty5);
      }
      button.ty5:hover, a.ty5:hover,
      button.ty5:focus, a.ty5:focus {
        color: var(--tms-button-hover-color-ty5)
      }

      :host(.ty6) {
        background: var(--tms-button-background-ty6);
        border: var(--tms-button-border-ty6);
        box-shadow: var(--tms-button-shadow-ty6);
      }
      :host(.ty6:hover),
      :host(.ty6:focus) {
        background: var(--tms-button-hover-background-color-ty6);
      }
      :host(.ty6) svg {
        fill: var(--tms-button-icon-color-ty6);
      }
      button.ty6, a.ty6, button.ty6 ::slotted(*) {
        color: var(--tms-button-color-ty6);
      }
      button.ty6:hover, a.ty6:hover,
      button.ty6:focus, a.ty6:focus {
        color: var(--tms-button-hover-color-ty6)
      }
      
  `;
__decorate([
    property({ type: Boolean, reflect: true })
], TButtonElement.prototype, "disabled", void 0);
__decorate([
    property({ type: String })
], TButtonElement.prototype, "href", void 0);
__decorate([
    property({ type: String })
], TButtonElement.prototype, "target", void 0);
__decorate([
    property({ type: VARIANT_TYPE })
], TButtonElement.prototype, "variant", void 0);
__decorate([
    property({ type: BUTTON_COLOR_TYPE, reflect: true })
], TButtonElement.prototype, "class", void 0);
__decorate([
    property({ type: ICON_TYPE, attribute: 'prefix-icon' })
], TButtonElement.prototype, "prefixIcon", void 0);
__decorate([
    property({ type: ICON_TYPE, attribute: 'suffix-icon' })
], TButtonElement.prototype, "suffixIcon", void 0);
__decorate([
    property({ type: ICON_TYPE })
], TButtonElement.prototype, "icon", void 0);
TButtonElement = __decorate([
    customElement(elementName)
], TButtonElement);
export { TButtonElement };

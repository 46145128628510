import { Context } from '@nuxt/types'

import Cookies from 'universal-cookie'

export default (context: Context) => {
  context.$cookies = new Cookies(context.req?.headers?.cookie)
}

declare module '@nuxt/types' {
  interface Context {
    $cookies: Cookies
  }
}

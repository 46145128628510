import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _db2d9450 = () => interopDefault(import('../pages/health-check/index.vue' /* webpackChunkName: "pages/health-check/index" */))
const _1f6bca58 = () => interopDefault(import('../pages/common/NotDefineComponentPage.vue' /* webpackChunkName: "pages/common/NotDefineComponentPage" */))
const _01d59edd = () => interopDefault(import('../pages/company/CompanyDetailPage.vue' /* webpackChunkName: "pages/company/CompanyDetailPage" */))
const _690e476a = () => interopDefault(import('../pages/company/CompanyListPage.vue' /* webpackChunkName: "pages/company/CompanyListPage" */))
const _0513e01a = () => interopDefault(import('../pages/dashboard/DashboardPage.vue' /* webpackChunkName: "pages/dashboard/DashboardPage" */))
const _5af167e4 = () => interopDefault(import('../pages/enterprise/EnterpriseListPage.vue' /* webpackChunkName: "pages/enterprise/EnterpriseListPage" */))
const _63ec6d3d = () => interopDefault(import('../pages/manager/ManagerDetailPage.vue' /* webpackChunkName: "pages/manager/ManagerDetailPage" */))
const _0575a46c = () => interopDefault(import('../pages/manager/ManagerListPage.vue' /* webpackChunkName: "pages/manager/ManagerListPage" */))
const _9f3d9c5c = () => interopDefault(import('../pages/media-publish/MediaPublishDetailPage.vue' /* webpackChunkName: "pages/media-publish/MediaPublishDetailPage" */))
const _d847d8c2 = () => interopDefault(import('../pages/media-publish/MediaPublishListPage.vue' /* webpackChunkName: "pages/media-publish/MediaPublishListPage" */))
const _457b192e = () => interopDefault(import('../pages/membership/login.vue' /* webpackChunkName: "pages/membership/login" */))
const _23f0ec21 = () => interopDefault(import('../pages/monitoring/MonitoringDetailPage.vue' /* webpackChunkName: "pages/monitoring/MonitoringDetailPage" */))
const _496415ae = () => interopDefault(import('../pages/monitoring/MonitoringListPage.vue' /* webpackChunkName: "pages/monitoring/MonitoringListPage" */))
const _138d5baf = () => interopDefault(import('../pages/permission/PermissionInfo.vue' /* webpackChunkName: "pages/permission/PermissionInfo" */))
const _4c4b079b = () => interopDefault(import('../pages/recommend/RecommendDetailPage.vue' /* webpackChunkName: "pages/recommend/RecommendDetailPage" */))
const _72ba70b0 = () => interopDefault(import('../pages/recommend/RecommendListPage.vue' /* webpackChunkName: "pages/recommend/RecommendListPage" */))
const _27876264 = () => interopDefault(import('../pages/sample/code.vue' /* webpackChunkName: "pages/sample/code" */))
const _0f032e66 = () => interopDefault(import('../pages/sample/date-picker.vue' /* webpackChunkName: "pages/sample/date-picker" */))
const _d39a01f0 = () => interopDefault(import('../pages/sample/dropdown.vue' /* webpackChunkName: "pages/sample/dropdown" */))
const _74f060e3 = () => interopDefault(import('../pages/sample/input.vue' /* webpackChunkName: "pages/sample/input" */))
const _2691581e = () => interopDefault(import('../pages/sample/sampleDirective.vue' /* webpackChunkName: "pages/sample/sampleDirective" */))
const _2fd5da35 = () => interopDefault(import('../pages/sample/sampleUI.vue' /* webpackChunkName: "pages/sample/sampleUI" */))
const _5cb68455 = () => interopDefault(import('../pages/sample/sampleUITag.vue' /* webpackChunkName: "pages/sample/sampleUITag" */))
const _75c079e6 = () => interopDefault(import('../pages/sample/smapleUrl.vue' /* webpackChunkName: "pages/sample/smapleUrl" */))
const _fbf87db8 = () => interopDefault(import('../pages/tag/TagListPage.vue' /* webpackChunkName: "pages/tag/TagListPage" */))
const _a2a5737e = () => interopDefault(import('../pages/user/UserDetailPage.vue' /* webpackChunkName: "pages/user/UserDetailPage" */))
const _949f1064 = () => interopDefault(import('../pages/user/UserListPage.vue' /* webpackChunkName: "pages/user/UserListPage" */))
const _c77ea0e4 = () => interopDefault(import('../pages/withdrawal/WithdrawalListPage.vue' /* webpackChunkName: "pages/withdrawal/WithdrawalListPage" */))
const _3e84df72 = () => interopDefault(import('../pages/membership/password/find.vue' /* webpackChunkName: "pages/membership/password/find" */))
const _18956e34 = () => interopDefault(import('../pages/membership/password/reset.vue' /* webpackChunkName: "pages/membership/password/reset" */))
const _67b18b8e = () => interopDefault(import('../pages/membership/password/set.vue' /* webpackChunkName: "pages/membership/password/set" */))
const _940218f8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/health-check",
    component: _db2d9450,
    name: "health-check"
  }, {
    path: "/common/NotDefineComponentPage",
    component: _1f6bca58,
    name: "common-NotDefineComponentPage"
  }, {
    path: "/company/CompanyDetailPage",
    component: _01d59edd,
    name: "company-CompanyDetailPage"
  }, {
    path: "/company/CompanyListPage",
    component: _690e476a,
    name: "company-CompanyListPage"
  }, {
    path: "/dashboard/DashboardPage",
    component: _0513e01a,
    name: "dashboard-DashboardPage"
  }, {
    path: "/enterprise/EnterpriseListPage",
    component: _5af167e4,
    name: "enterprise-EnterpriseListPage"
  }, {
    path: "/manager/ManagerDetailPage",
    component: _63ec6d3d,
    name: "manager-ManagerDetailPage"
  }, {
    path: "/manager/ManagerListPage",
    component: _0575a46c,
    name: "manager-ManagerListPage"
  }, {
    path: "/media-publish/MediaPublishDetailPage",
    component: _9f3d9c5c,
    name: "media-publish-MediaPublishDetailPage"
  }, {
    path: "/media-publish/MediaPublishListPage",
    component: _d847d8c2,
    name: "media-publish-MediaPublishListPage"
  }, {
    path: "/membership/login",
    component: _457b192e,
    name: "membership-login"
  }, {
    path: "/monitoring/MonitoringDetailPage",
    component: _23f0ec21,
    name: "monitoring-MonitoringDetailPage"
  }, {
    path: "/monitoring/MonitoringListPage",
    component: _496415ae,
    name: "monitoring-MonitoringListPage"
  }, {
    path: "/permission/PermissionInfo",
    component: _138d5baf,
    name: "permission-PermissionInfo"
  }, {
    path: "/recommend/RecommendDetailPage",
    component: _4c4b079b,
    name: "recommend-RecommendDetailPage"
  }, {
    path: "/recommend/RecommendListPage",
    component: _72ba70b0,
    name: "recommend-RecommendListPage"
  }, {
    path: "/sample/code",
    component: _27876264,
    name: "sample-code"
  }, {
    path: "/sample/date-picker",
    component: _0f032e66,
    name: "sample-date-picker"
  }, {
    path: "/sample/dropdown",
    component: _d39a01f0,
    name: "sample-dropdown"
  }, {
    path: "/sample/input",
    component: _74f060e3,
    name: "sample-input"
  }, {
    path: "/sample/sampleDirective",
    component: _2691581e,
    name: "sample-sampleDirective"
  }, {
    path: "/sample/sampleUI",
    component: _2fd5da35,
    name: "sample-sampleUI"
  }, {
    path: "/sample/sampleUITag",
    component: _5cb68455,
    name: "sample-sampleUITag"
  }, {
    path: "/sample/smapleUrl",
    component: _75c079e6,
    name: "sample-smapleUrl"
  }, {
    path: "/tag/TagListPage",
    component: _fbf87db8,
    name: "tag-TagListPage"
  }, {
    path: "/user/UserDetailPage",
    component: _a2a5737e,
    name: "user-UserDetailPage"
  }, {
    path: "/user/UserListPage",
    component: _949f1064,
    name: "user-UserListPage"
  }, {
    path: "/withdrawal/WithdrawalListPage",
    component: _c77ea0e4,
    name: "withdrawal-WithdrawalListPage"
  }, {
    path: "/membership/password/find",
    component: _3e84df72,
    name: "membership-password-find"
  }, {
    path: "/membership/password/reset",
    component: _18956e34,
    name: "membership-password-reset"
  }, {
    path: "/membership/password/set",
    component: _67b18b8e,
    name: "membership-password-set"
  }, {
    path: "/",
    component: _940218f8,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

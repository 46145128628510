import { render, staticRenderFns } from "./ConfirmByThreeButton.vue?vue&type=template&id=1f81e6c3&scoped=true"
import script from "./ConfirmByThreeButton.vue?vue&type=script&setup=true&lang=ts"
export * from "./ConfirmByThreeButton.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./ConfirmByThreeButton.vue?vue&type=style&index=0&id=1f81e6c3&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f81e6c3",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ButtonPrimary: require('/apps/src/ound-admin/apps/admin/components/button/ButtonPrimary.vue').default})


import { Context } from '@nuxt/types'
import Vue from 'vue'
import Loading from './Loading.vue'

 declare module '@nuxt/types' {
   interface Context {
     $loading: any
   }
 }

export default (context: Context) => {
  const LoadingEl = Vue.extend(Loading)
  const vm: any = new LoadingEl().$mount()
  document.body.appendChild(vm.$el)

  const start = () => {
    vm.start()
    // context.app.$loading.start()
  }

  const end = () => {
    vm.end()
  }

  context.$loading = {
    start: () => start(),
    end: () => end()
  }
}

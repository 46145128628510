var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { customElement } from "lit/decorators.js";
import { TTagElement } from "./base-tag";
import { prefixName } from "../types/types";
const tagType = 'non-duplicate';
const elementName = `${prefixName}-${tagType}-tag`;
let TNonDuplicateTagList = class TNonDuplicateTagList extends TTagElement {
    constructor() {
        super();
        /**
         * @override
         * input tag blur 이벤트
         */
        this.changeUiTag = (e) => {
            e.preventDefault();
            this.onDuplicatedCheck();
        };
        this.changeTag = () => {
            this._isEdit = false;
            this._tagName = this.input.value;
        };
        this.focusInputTag = () => {
            this._isEdit = true; //_isEdit true 인경우 부모에서 focus 제어
        };
        /**
         * @override
         */
        this.onChangeInputTag = () => { };
        this.onDuplicatedCheck = () => {
            this.dispatchEvent(new CustomEvent('duplicated-check', { detail: { oldText: this.tagName, text: this.input.value, value: this.value, success: this.changeTag }, bubbles: true, composed: false }));
        };
    }
};
TNonDuplicateTagList = __decorate([
    customElement(elementName)
], TNonDuplicateTagList);
export { TNonDuplicateTagList };

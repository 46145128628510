
import { defineComponent, ref } from '@nuxtjs/composition-api'

export default defineComponent({
  setup () {
    const isLoading = ref(false)

    const start = () => {
      isLoading.value = true
    }

    const end = () => {
      isLoading.value = false
    }

    return {
      isLoading,
      start,
      end
    }
  }
})


export const TAB_INFO = {
  DASHBOARD: {
    URL: '/page/dashboard/index',
    VIEW: 'DashboardPage'
  },
  MANAGER_LIST: {
    URL: '/page/manager/list',
    VIEW: 'ManagerListPage'
  },
  MANAGER_DETAIL: {
    URL: '/page/manager/detail',
    VIEW: 'ManagerDetailPage'
  },
  MENU_GROUP_LIST: {
    URL: '/page/authority/list',
    VIEW: 'PermissionInfo'
  },
  MENU_GROUP_DETAIL: {
    URL: '/page/authority-group/detail',
    VIEW: 'NotDefineComponentPage'
  },
  MENU_GROUP_MANAGER_LIST: {
    URL: '/page/authority-group/manager/list',
    VIEW: 'NotDefineComponentPage'
  },
  USER_LIST: {
    URL: '/page/user/list',
    VIEW: 'UserListPage'
  },
  USER_DETAIL: {
    URL: '/page/user/detail',
    VIEW: 'UserDetailPage'
  },
  MEDIA_PUBLISH_LIST: {
    URL: '/page/media-publish/list',
    VIEW: 'MediaPublishListPage'
  },
  MEDIA_PUBLISH_DETAIL: {
    URL: '/page/media-publish/detail',
    VIEW: 'MediaPublishDetailPage'
  },
  WITHDRAWAL_LIST: {
    URL: '/page/withdrawal/list',
    VIEW: 'WithdrawalListPage'
  },
  TAG_LIST: {
    URL: '/page/tag/list',
    VIEW: 'TagListPage'
  },
  RECOMMEND_LIST: {
    URL: '/page/recommend/list',
    VIEW: 'RecommendListPage'
  },
  RECOMMEND_DETAIL: {
    URL: '/page/recommend/detail',
    VIEW: 'RecommendDetailPage'
  },
  COMPANY_LIST: {
    URL: '/page/company/list',
    VIEW: 'CompanyListPage'
  },
  COMPANY_DETAIL: {
    URL: '/page/company/detail',
    VIEW: 'CompanyDetailPage'
  },
  MONITORING_LIST: {
    URL: '/page/monitoring/list',
    VIEW: 'MonitoringListPage'
  },
  MONITORING_DETAIL: {
    URL: '/page/monitoring/detail',
    VIEW: 'MonitoringDetailPage'
  }
}

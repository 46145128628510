import { Store } from 'vuex'
import { getModule } from 'vuex-module-decorators'
import CodeStore from '../codeStore'
import MenuStore from '../menuStore'
import TabStore from '../tabStore'
import SampelModule from '../sample'
import SampleStorage from '../sampleStorage'

export let sample: SampelModule
export let sampleStorage: SampleStorage
export let codeStore: CodeStore
export let menuStore: MenuStore
export let tabStore: TabStore

export function initialiseStores (store: Store<any>): void {
  sample = getModule(SampelModule, store)
  sampleStorage = getModule(SampleStorage, store)
  menuStore = getModule(MenuStore, store)
  tabStore = getModule(TabStore, store)
  codeStore = getModule(CodeStore, store)
}

import {
  ILoginRequest,
  ILoginResponse,
  ILogoutResponse,
  IProfileResponse,
  ITokenResponse
} from '../types'
import axiosHelper, { AxiosResponse } from '~/plugins/axios/api-helper'

export const LoginAPI = (param: ILoginRequest): Promise<ILoginResponse> =>
  axiosHelper.post('/membership/v1/login', param)
    .then((response: AxiosResponse<ILoginResponse>) => response.data)

export const LogoutAPI = (): Promise<ILogoutResponse> =>
  axiosHelper.post('/membership/v1/logout')
    .then((response: AxiosResponse<ILogoutResponse>) => response.data)

export const TokenCheckAPI = (config?: object): Promise<ITokenResponse> =>
  axiosHelper.post('/oauth/v1/token/check', {}, config ?? {})
    .then((response: AxiosResponse<ITokenResponse>) => response.data)

export const SelectProfileAPI = (config?: object): Promise<IProfileResponse> =>
  axiosHelper.get('/manager/v1/account/profile', {}, config ?? {})
    .then((response: AxiosResponse<IProfileResponse>) => response.data)

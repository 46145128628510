export interface Tab {
  id: string
  title: string
  url: string
  type: number
  menuSeq: number | string
  option?: any
}

export const TAB_TYPE = {
  LIST: 0,
  REGISTER: 1,
  DETAIL: 2
} as const
// eslint-disable-next-line no-redeclare
export type TAB_TYPE = (typeof TAB_TYPE)[keyof typeof TAB_TYPE]

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InputCondition = exports.InputFormatter = void 0;
const FORMAT_TYPE = {
    phoneDash: "phoneDash",
    date: "date",
    dateDash: "dateDash",
    numberComma: "numberComma"
};
/**
 * 포맷팅 method name
 */
const FUNCTION_NAME_TYPE = {
    formatPhone: "formatPhone",
    formatDate: "formatDate",
    formatNumberComma: "formatNumberComma"
};
class InputFormatter {
    constructor(binding) {
        this.functionName = "";
        /** 글자 input 이벤트 callback */
        this.onChangeEvent = (event) => {
            const target = event.target;
            const initalValue = target.value;
            target.value = this.callMethod(initalValue);
            if (initalValue !== target.value) {
                event.stopPropagation();
            }
        };
        this.binding = binding;
        this.functionName = this.setFunctionName();
    }
    setFunctionName() {
        const type = this.binding.formatType;
        switch (type) {
            case FORMAT_TYPE.phoneDash:
                return FUNCTION_NAME_TYPE.formatPhone;
            case FORMAT_TYPE.date:
                return FUNCTION_NAME_TYPE.formatDate;
            case FORMAT_TYPE.dateDash:
                return FUNCTION_NAME_TYPE.formatDateDash;
            case FORMAT_TYPE.numberComma:
                return FUNCTION_NAME_TYPE.formatNumberComma;
            default:
                return "";
        }
    }
    /**
     * 핸드폰 formmater
     *
     * 000-0000-0000
     */
    ["formatPhone"](str) {
        return str.replace(/[^0-9]/g, "").replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
    }
    /**
     * 날짜 formmater
     *
     * yy/mm/dd
     */
    ["formatDate"](str) {
        return str.replace(/[^0-9]/g, "").replace(/^(\d{2,2})(\d{2,2})(\d{2})$/, `$1/$2/$3`);
    }
    /**
     * 날짜 formmater
     *
     * yy-mm-dd
     */
    ["formatDateDash"](str) {
        return str.replace(/[^0-9]/g, "").replace(/^(\d{2,2})(\d{2,2})(\d{2})$/, `$1-$2-$3`);
    }
    /**
     * 숫자 comma formmater
     *
     * 000,000,000
     */
    ["formatNumberComma"](str) {
        let numberStr = str;
        numberStr = numberStr.replace(/[^\d]+/g, "");
        return numberStr.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
    }
    /**
     * method name으로 동적 호출
     *
     * 000-0000-0000
     */
    callMethod(text) {
        const key = this.functionName;
        if (!this[key])
            return text;
        return this[key](text);
    }
}
exports.InputFormatter = InputFormatter;
/** event bind */
const inserted = (el, binding) => {
    const value = binding.value;
    const onlyText = new InputFormatter(value);
    el.addEventListener("input", onlyText.onChangeEvent, { passive: true });
};
const unbind = (el, binding) => {
    const value = binding.value;
    const onlyText = new InputFormatter(value);
    el.removeEventListener("input", onlyText.onChangeEvent);
};
exports.InputCondition = {
    inserted,
    unbind
};
exports.default = exports.InputCondition;

export const PHASE_TYPE = {
  DEV: 'DEV',
  QA: 'QA',
  PROD: 'PROD'
} as const
// eslint-disable-next-line no-redeclare
export type PHASE_TYPE = (typeof PHASE_TYPE)[keyof typeof PHASE_TYPE]

export const MODE_TYPE = {
  LOG: 0,
  INFO: 1,
  WARN: 2,
  ERROR: 3,
  OFF: 4
} as const
// eslint-disable-next-line no-redeclare
export type MODE_TYPE = (typeof MODE_TYPE)[keyof typeof MODE_TYPE]
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type ValuesOf<E> = E[keyof E]

export type LOGGING_TYPE = 'log' | 'info' | 'warn' | 'error'

import { render, staticRenderFns } from "./Confirm.vue?vue&type=template&id=08d2679a&scoped=true"
import script from "./Confirm.vue?vue&type=script&setup=true&lang=ts"
export * from "./Confirm.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./Confirm.vue?vue&type=style&index=0&id=08d2679a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08d2679a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ButtonPrimary: require('/apps/src/ound-admin/apps/admin/components/button/ButtonPrimary.vue').default})

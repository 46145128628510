import Vue from 'vue'
import VCalendar from 'v-calendar'

Vue.use(VCalendar as any, {
  masks: {
    input: 'YY/MM/DD'
  },
  datePicker: {
    popover: {
      visibility: 'click'
    }
  }
})

import { Plugin } from '@nuxt/types'
import Vue from 'vue'
import Alert from './Alert.vue'
import Confirm from './Confirm.vue'
import ConfirmByThreeButton from './ConfirmByThreeButton.vue'
import DeleteConfirm from './DeleteConfirm.vue'
import { AlertConfig, ConfirmConfig, ConfirmByThreeButtonConfig, DeleteConfirmConfig } from './types'

declare module '@nuxt/types' {
  interface Context {
    $alert: any
    $confirm: any
    $confirmByThreeButton: any
    $deleteConfirm: any
  }
}

const snackbarPlugin: Plugin = (context) => {
  const AlertEl = Vue.extend(Alert)
  const alertVm: any = new AlertEl().$mount()
  document.body.appendChild(alertVm.$el)

  const ConfirmEl = Vue.extend(Confirm)
  const confirmVm: any = new ConfirmEl().$mount()
  document.body.appendChild(confirmVm.$el)

  const ConfirmByThreeButtonEl = Vue.extend(ConfirmByThreeButton)
  const ConfirmByThreeButtonVm: any = new ConfirmByThreeButtonEl().$mount()
  document.body.appendChild(ConfirmByThreeButtonVm.$el)

  const DeleteConfirmEl = Vue.extend(DeleteConfirm)
  const deleteConfigVm: any = new DeleteConfirmEl().$mount()
  document.body.appendChild(deleteConfigVm.$el)

  context.$alert = (config: AlertConfig) => {
    alertVm.open(config)
  }

  context.$confirm = (config: ConfirmConfig) => {
    confirmVm.open(config)
  }

  context.$confirmByThreeButton = (config: ConfirmByThreeButtonConfig) => {
    ConfirmByThreeButtonVm.open(config)
  }

  context.$deleteConfirm = (config: DeleteConfirmConfig) => {
    deleteConfigVm.open(config)
  }
}

export default snackbarPlugin

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createExcelFileToHtml = exports.createExcelFileToJson = exports.xlsxUtil = void 0;
const xlsx_js_style_1 = require("xlsx-js-style");
Object.defineProperty(exports, "xlsxUtil", { enumerable: true, get: function () { return xlsx_js_style_1.utils; } });
const setSheetWidth = (workSheet, optionsWidth) => {
    if (!(optionsWidth && optionsWidth.length > 0))
        return;
    const widthArrayList = [];
    optionsWidth.forEach((data) => {
        widthArrayList.push({ width: data });
    });
    workSheet["!cols"] = widthArrayList;
};
const setStyleHeader = (workSheet) => {
    const sheetRange = workSheet['!ref'];
    if (sheetRange) {
        const firstRowRange = xlsx_js_style_1.utils.decode_range(sheetRange);
        for (let col = firstRowRange.s.c; col <= firstRowRange.e.c; col++) {
            const cellAddress = xlsx_js_style_1.utils.encode_cell({ r: 0, c: col });
            workSheet[cellAddress].s = { fill: { fgColor: { rgb: '000000' } }, font: { color: { rgb: 'FFFFFF' } } };
        }
    }
};
//json으로 엑셀 파일 생성
const createExcelFileToJson = (fileName, jsonData, options) => {
    const workbook = xlsx_js_style_1.utils.book_new(); //워크시트 생성
    const workSheet = xlsx_js_style_1.utils.json_to_sheet(jsonData, { skipHeader: false, cellStyles: true });
    const headerNames = options === null || options === void 0 ? void 0 : options.headerNames;
    if (headerNames && headerNames.length > 0) {
        xlsx_js_style_1.utils.sheet_add_aoa(workSheet, [headerNames]); //1번째 줄 header 세팅
    }
    //엑셀 너비 세팅
    const cellWidth = options === null || options === void 0 ? void 0 : options.cellWidth;
    setSheetWidth(workSheet, cellWidth);
    //header 색상
    setStyleHeader(workSheet);
    if ((options === null || options === void 0 ? void 0 : options.formula) && typeof (options === null || options === void 0 ? void 0 : options.formula) === "function") {
        options === null || options === void 0 ? void 0 : options.formula(workSheet);
    }
    xlsx_js_style_1.utils.book_append_sheet(workbook, workSheet);
    return (0, xlsx_js_style_1.writeFile)(workbook, `${fileName}.xlsx`, { cellStyles: true });
};
exports.createExcelFileToJson = createExcelFileToJson;
//html으로 엑셀 파일 생성
const createExcelFileToHtml = (fileName, el, options) => {
    const workbook = xlsx_js_style_1.utils.table_to_book(el);
    const workSheet = workbook.Sheets["Sheet1"];
    //엑셀 너비 세팅
    const cellWidth = options === null || options === void 0 ? void 0 : options.cellWidth;
    setSheetWidth(workSheet, cellWidth);
    return (0, xlsx_js_style_1.writeFile)(workbook, `${fileName}.xlsx`);
};
exports.createExcelFileToHtml = createExcelFileToHtml;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stripXSSTags = exports.formatBMK = exports.setComma = exports.isEmpty = void 0;
/**
 * 데이터의 빈값 여부를 확인합니다
 *
 * @category 문자
 * @example
 * null, "", " ", [], {} 모두 true로 반환합니다
 *
 * ```javascript
 * const str = "";
 * const result = isEmpty(str);
 * ```
 * 결과값: true
 *
 **/
const isEmpty = (obj) => {
    if (typeof obj === "undefined" ||
        obj === null ||
        obj.length === 0 ||
        obj === "" ||
        !/[^\s]/.test(obj) ||
        /^\s*$/.test(obj) ||
        (obj !== null && typeof obj === "object" && !Object.keys(obj).length)) {
        return true;
    }
    else {
        return false;
    }
};
exports.isEmpty = isEmpty;
const convertStringToNumber = (str) => {
    let numberStr = str;
    if (typeof numberStr === "string") {
        numberStr = Number(numberStr);
    }
    return numberStr;
};
/**
 * 3자리 숫자마다 ,를 표시합니다
 * 금액 표시할때 사용합니다
 *
 * @category 숫자
 * @example
 * ```javascript
 * const str = 20000;
 * const result = setComma(str);
 * ```
 * 결과값: "20,000"
 *
 **/
const setComma = (str, locale = "ko-KR") => {
    const numberStr = convertStringToNumber(str);
    if (isNaN(numberStr)) {
        return str.toString();
    }
    return new Intl.NumberFormat(locale).format(numberStr);
};
exports.setComma = setComma;
const formatBMK = (value) => {
    const numberStr = convertStringToNumber(value);
    if (isNaN(numberStr)) {
        return {
            value: `${value}`,
            unit: ""
        };
    }
    if (value >= 1e9) {
        //십억
        return {
            value: `${numberStr / 1e9}`,
            unit: "b"
        };
    }
    if (value >= 1e6) {
        //백만
        return {
            value: `${numberStr / 1e6}`,
            unit: "m"
        };
    }
    if (value >= 1e3) {
        //천
        return {
            value: `${numberStr / 1e3}`,
            unit: "k"
        };
    }
    return {
        value: `${value}`,
        unit: ""
    };
};
exports.formatBMK = formatBMK;
/**
 * html xss script 방지
 * html xss script 방지를 위해 text를 convert 합니다
 *
 * @category 숫자
 * @example
 * ```javascript
 * const str = 20000;
 * const result = setComma(str);
 * ```
 * 결과값: "20,000"
 *
 **/
const stripXSSTags = (value) => {
    const regex = /(<script.*>)|(<\/ *script.*>)|(<iframe.*>)|(<\/ *iframe.*>)/gm;
    const str = value.replace(regex, "");
    return str;
};
exports.stripXSSTags = stripXSSTags;

import { render, staticRenderFns } from "./DeleteConfirm.vue?vue&type=template&id=0965e347&scoped=true"
import script from "./DeleteConfirm.vue?vue&type=script&setup=true&lang=ts"
export * from "./DeleteConfirm.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./DeleteConfirm.vue?vue&type=style&index=0&id=0965e347&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0965e347",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Input: require('/apps/src/ound-admin/apps/admin/pages/sample/input.vue').default})

import { ApiResponse } from '~/service/types'

export const ERROR_CODE_SESSION_EXPIRATION: number = 403

export interface IToken {
  accessToken: string
  refreshToken: string
}

export interface ILoginRequest {
  managerId: string
  managerPwd: string
}

export interface ILoginResponse extends ApiResponse {
  data: IToken
}

export interface ILogoutResponse extends ApiResponse {}

export interface ITokenResponse extends ApiResponse {
  data: IToken
}

export interface IProfile {
  managerSeq: number
  managerId: string
  managerName: string
}

export interface IProfileResponse extends ApiResponse {
  data: {
    profile: IProfile
  }
}

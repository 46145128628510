"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.subtractDayStr = exports.subtractDay = exports.afterDayStr = exports.afterDay = exports.convertStringDateToTimestamp = exports.convertTimestampToStringDate = exports.converStringToDateFormat = exports.converStringToDate = exports.convertDateFormat = void 0;
const date_fns_1 = require("date-fns");
const defaultDateFormat = "yyyy-MM-dd";
/**
 * date 타입을 원하는 format으로 변환합니다
 *
 * @returns 변환된 string 형태의 날짜값을 변환합니다
 * @category 날짜변환
 * @example
 * format 지정 없을때
 *
 * ```javascript
 *   const dateStr = "2022-12-13 10:03:20";
 *   const date = new Date(dateStr);
 *   const result = convertDateFormat({ date });
 * ```
 * 결과값: "2022-12-13"
 *
 * @example
 * format 지정할때
 *
 *  ```javascript
 *    const dateStr = "2022-12-13 10:03:20";
 *    const format = "yyyy.MM.dd HH:mm:ss";
 *    const date = new Date(dateStr);
 *    const result = convertDateFormat({ date, format });
 *  ```
 * 결과값: "2022-12-13 10:03:20"
 *
 **/
const convertDateFormat = (payload) => {
    var _a;
    const date = payload.date;
    if ((0, date_fns_1.isValid)(date)) {
        return (0, date_fns_1.format)(date, (_a = payload.format) !== null && _a !== void 0 ? _a : defaultDateFormat);
    }
    throw new Error("Invalid date format");
};
exports.convertDateFormat = convertDateFormat;
/**
 * string을 타입을 date 타입으로 변환
 *
 * @returns 변환된 Date타입 데이터
 * @category 날짜변환
 * @example
 * format 지정 없을때
 *
 * ```javascript
 *   const dateStr = "2022-12-13 10:03:20";
 *
 *   const date = converStringToDate(str);
 * ```
 * 결과값: "2022-12-13"
 *
 * @example
 * format 지정할때
 *
 * ```javascript
 *   const dateStr = "2022-12-13 10:03:20";
 *   const format = "yyyy.MM.dd HH:mm:ss";
 *
 *   const date = converStringToDate(str);
 * ```
 * 결과값: "2022-12-13 10:03:20"
 *
 **/
const converStringToDate = (dateStr) => {
    const date = new Date(dateStr);
    if ((0, date_fns_1.isValid)(date)) {
        return date;
    }
    throw new Error("Invalid date format");
};
exports.converStringToDate = converStringToDate;
/**
 * string 타입의 날짜를 원하는 format으로 변환합니다
 *
 * @category 날짜변환
 * @example
 * format 지정 없을때
 *
 * ```javascript
 *  const date: string = converStringToDateFormat({
 *    dateStr: "2022-12-13 10:00:00"
 *  });
 * ```
 * 결과값: "2022-12-13"
 *
 * @example
 * format 지정할때
 *
 * ```javascript
 *  const date: string = converStringToDateFormat({
 *    dateStr: "2022-12-13 10:00:00",
 *    format: "yyyy-MM-dd HH:mm"
 *  });
 * ```
 * 결과값: "2022-12-13 10:03"
 *
 **/
const converStringToDateFormat = (payload) => {
    const date = (0, exports.converStringToDate)(payload.dateStr);
    const convertFormatparam = {
        date,
        format: payload.format
    };
    return (0, exports.convertDateFormat)(convertFormatparam);
};
exports.converStringToDateFormat = converStringToDateFormat;
/**
 * timestamp를 string 날짜형태로 변환합니다
 *
 * @category 날짜변환
 * @example
 * format 지정 없을때
 *
 * ```javascript
 * const date = convertTimestampToStringDate({
 *  timestamp: 1670909060053
 * });
 * ```
 * 결과값: "2022-12-13"
 *
 * @example
 * format 지정할때
 *
 * ```javascript
 * const date = convertTimestampToStringDate({
 *  timestamp: 1670909060053,
 *  format: "yyyy-MM-dd HH:mm"
 * });
 * ```
 * 결과값: "2022-12-13 14:24"
 *
 **/
const convertTimestampToStringDate = (payload) => {
    const convertFormatparam = {
        date: new Date(payload.timestamp * 1000),
        format: payload.format
    };
    return (0, exports.convertDateFormat)(convertFormatparam);
};
exports.convertTimestampToStringDate = convertTimestampToStringDate;
/**
 * string 형태의 날짜를 timestamp로 변환합니다
 *
 * @category 날짜변환
 * @example
 * format 지정 없을때
 *
 * ```javascript
 * const dateStr = "2022-12-13";
 * const date = convertStringDateToTimestamp({ dateStr });
 * ```
 * 결과값: 1670889600000
 *
 * @example
 * format 지정할때
 *
 * ```javascript
 * const dateStr = "2022-12-13 14:24:20";
 * const date = convertStringDateToTimestamp({ dateStr });
 * ```
 * 결과값: 1670909060000
 *
 **/
const convertStringDateToTimestamp = (dateStr) => {
    const date = (0, exports.converStringToDate)(dateStr);
    return date.getTime();
};
exports.convertStringDateToTimestamp = convertStringDateToTimestamp;
/**
 * Date형태 날짜의 n일 후를 계산합니다
 *
 * @category 날짜계산
 *
 * @example
 *
 * ```javascript
 *  const dateStr = "2022-12-13";
 *  const amount = 5;
 *  const payload = {
 *    dateStr,
 *    amount
 *  };
 *  const date = afterDay(payload);
 * ```
 *
 **/
const afterDay = (payload) => {
    const date = (0, exports.converStringToDate)(payload.dateStr);
    const futureDate = (0, date_fns_1.addDays)(date, payload.amount);
    return futureDate;
};
exports.afterDay = afterDay;
/**
 * string 형태 날짜의 n일 후를 계산합니다
 *
 * @category 날짜계산
 * @example
 * format 지정 없을때
 *
 * ```javascript
 *  const dateStr = "2022-12-13";
 *  const amount = 5;
 *  const payload = {
 *    dateStr,
 *    amount
 *  };
 *  const date = afterDayStr(payload);
 * ```
 * 결과값: '2022-12-18'
 *
 * @example
 * format 지정할때
 *
 * ```javascript
 *  const format = "yyyy.MM.dd";
 *  const dateStr = "2022-12-13";
 *  const amount = 5;
 *  const payload = {
 *    dateStr,
 *    amount,
 *    format
 *  };
 *  const date = afterDayStr(payload);
 * ```
 * 결과값: '2022.12.18'
 *
 **/
const afterDayStr = (payload) => {
    const date = (0, exports.afterDay)(payload);
    const result = (0, exports.convertDateFormat)({
        date,
        format: payload.format
    });
    return result;
};
exports.afterDayStr = afterDayStr;
/**
 * Date형태 날짜의 n일전 을 계산합니다
 *
 * @category 날짜계산
 *
 * @example
 *
 * ```javascript
 *  const dateStr = "2022-12-13";
 *  const amount = 5;
 *  const payload = {
 *    dateStr,
 *    amount
 *  };
 *  const date = subtractDay(payload);
 * ```
 *
 **/
const subtractDay = (payload) => {
    const date = (0, exports.converStringToDate)(payload.dateStr);
    const futureDate = (0, date_fns_1.subDays)(date, payload.amount);
    return futureDate;
};
exports.subtractDay = subtractDay;
/**
 * string 형태 날짜의 n일전을 계산합니다
 *
 * @category 날짜계산
 * @example
 * format 지정 없을때
 *
 * ```javascript
 *  const dateStr = "2022-12-13";
 *  const amount = 5;
 *  const payload = {
 *    dateStr,
 *    amount
 *  };
 *  const date = subtractDayStr(payload);
 * ```
 * 결과값: '2022-12-08'
 *
 * @example
 * format 지정할때
 *
 * ```javascript
 *  const format = "yyyy.MM.dd";
 *  const dateStr = "2022-12-13";
 *  const amount = 5;
 *  const payload = {
 *    dateStr,
 *    amount,
 *    format
 *  };
 *  const date = subtractDayStr(payload);
 * ```
 * 결과값: '2022.12.08'
 *
 **/
const subtractDayStr = (payload) => {
    const date = (0, exports.subtractDay)(payload);
    const result = (0, exports.convertDateFormat)({
        date,
        format: payload.format
    });
    return result;
};
exports.subtractDayStr = subtractDayStr;

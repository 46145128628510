var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css } from "lit";
import { customElement } from "lit/decorators.js";
import { TButtonElement } from "./base-button";
import { prefixName } from "./types";
const buttonType = 'button';
const elementName = `${prefixName}-${buttonType}-large`;
let LargeButtonElement = class LargeButtonElement extends TButtonElement {
};
LargeButtonElement.styles = [
    TButtonElement.styles,
    css `
      button, a {
        height: var(--tms-button-height-large);
        font-size: var(--tms-button-font-size-large);
        font-weight: var(--tms-button-font-weight-large);
      }

      ::slotted(*) {
        font-size: var(--tms-button-font-size-small);
        font-weight: var(--tms-button-font-weight-small);
      }
    `
];
LargeButtonElement = __decorate([
    customElement(elementName)
], LargeButtonElement);
export { LargeButtonElement };

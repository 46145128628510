import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { ActionContext } from 'vuex'
import { ICodeGroup } from '@/service/code/types'

import axiosHelper from '~/plugins/axios/api-helper'

@Module({
  name: 'codeStore',
  stateFactory: true, // nuxt에서는 팩토리 함수 생성 필요
  namespaced: true
})
export default class CodeStore extends VuexModule {
  codeList: ICodeGroup[] = [] // 코드의 전체 목록

  get list () {
    return this.codeList
  }

  @Mutation
  setCodeList (codeList: ICodeGroup[]) {
    this.codeList = codeList
  }

  @Action({ rawError: true })
  async fetchCodeList (context: ActionContext<any, any>) {
    try {
      const userCodeResponse = await axiosHelper.get('/code/v1/all/list')
      const adminCodeResponse = await axiosHelper.get('/admin/code/v1/all/list')

      if (userCodeResponse.data.code === 0 && adminCodeResponse.data.code === 0) {
        const userCodeList: ICodeGroup[] = userCodeResponse.data.data.codeList
        const adminCodeList: ICodeGroup[] = adminCodeResponse.data.data.codeList

        const userCodeGroupList = userCodeList.map((code:any) => code.groupCd)
        const filteredAdminCodeList = adminCodeList.filter((code: any) => !userCodeGroupList.includes(code.groupCd))
        context.commit('codeStore/setCodeList', [...userCodeList, ...filteredAdminCodeList])
      } else {
        context.commit('codeStore/setCodeList', [])
      }
    } catch (error: any) {
      context.commit('codeStore/setCodeList', [])
    }
  }
}

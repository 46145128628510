import { Middleware } from '@nuxt/types'
import AuthService from '~/service/auth/auth-service'

import { ACCESS_TOKEN, REFRESH_TOKEN } from '~/service/auth/constants'

const authenticated: Middleware = (context) => {
  const accessToken = context.$cookies.get(ACCESS_TOKEN)
  const refreshToken = context.$cookies.get(REFRESH_TOKEN)

  const authService = new AuthService()

  if (!accessToken || !refreshToken) {
    context.$cookies.remove(ACCESS_TOKEN, { path: '/' })
    context.$cookies.remove(REFRESH_TOKEN, { path: '/' })
    context.redirect(authService.navigateToSessionExpiration())
  }
}
export default authenticated

import { Store, ActionContext } from 'vuex'
import { initialiseStores } from './core/store-accessor'
import AuthService from '~/service/auth/auth-service'
import { ACCESS_TOKEN, REFRESH_TOKEN } from '~/service/auth/constants'
import Config from '~/config/configuration'

const initializer = (store: Store<any>) => initialiseStores(store)
export const plugins = [initializer]
export * from './core/store-accessor'

export const actions = {
  async nuxtServerInit (actionContext: ActionContext<any, any>, context: any) {
    const domain = Config.COOKIE_DOMAIN

    await actionContext.dispatch('codeStore/fetchCodeList', actionContext)

    const authService = new AuthService()

    try {
      await authService.checkedSessionFromHeaderCookie(context.req.headers.cookie ?? '')
    } catch (err: any) {
      context.res.setHeader('Set-Cookie', [
        `${ACCESS_TOKEN}=; Max-Age=0; Path=/; DOMAIN=${domain}`,
        `${REFRESH_TOKEN}=; Max-Age=0; Path=/; DOMAIN=${domain}`
      ])
    }
    return true
  }
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.debounce = void 0;
function debounce(func, delay) {
    let timerId;
    let context;
    return function debouncedFunction(...args) {
        clearTimeout(timerId);
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        context = this;
        timerId = setTimeout(() => {
            func.apply(context, args);
        }, delay);
    };
}
exports.debounce = debounce;

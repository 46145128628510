import { StringUtil } from '@tms/utils'
import { EMAIL_REG, PASSWORD_REG, PHONE_REG } from './types'

export default class ValidatorService {
  checkedRequired (value: string): string {
    if (StringUtil.isEmpty(value)) {
      return '입력된 값이 없습니다'
    }
    return ''
  }

  checkedId (id: string): string {
    if (StringUtil.isEmpty(id)) {
      return '입력된 값이 없습니다'
    } else if (!EMAIL_REG.test(id)) {
      return '형식에 맞지 않는 값입니다'
    } else {
      return ''
    }
  }

  checkedPassword (password: string): string {
    if (StringUtil.isEmpty(password)) {
      return '입력된 값이 없습니다'
    } else if (!PASSWORD_REG.test(password)) {
      return '형식에 맞지 않는 값입니다'
    } else {
      return ''
    }
  }

  checkedRePassword (password: string, repassword: string): string {
    if (StringUtil.isEmpty(repassword)) {
      return '입력된 값이 없습니다'
    } else if (password !== repassword) {
      return '비밀번호가 일치하지 않습니다'
    } else {
      return ''
    }
  }

  checkedPhone (phone: string): string {
    if (StringUtil.isEmpty(phone)) {
      return '입력된 값이 없습니다'
    } else if (!PHONE_REG.test(phone)) {
      return '형식에 맞지 않는 값입니다'
    } else {
      return ''
    }
  }
}


import { computed, defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    error: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    const isNoSessionError = computed(() => props.error?.statusCode === 403)

    return {
      isNoSessionError
    }
  }
})
